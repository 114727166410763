var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('combo-select',{ref:"combo",attrs:{"label":_vm.labelText,"searchableFields":['asset.serial-number'],"text":_vm.text,"searchPlaceholder":_vm.labelText,"indexKey":"id","valueKey":_vm.valueKey,"only-use-options":_vm.onlyUseOptions,"options":_vm.options,"fixed":_vm.fixed,"visibleOnFocus":true,"query":require('@/graphql/InspectionCreateAutoFill.gql'),"dataKey":"paginateInspections","error":_vm.error,"allow-free-text":true,"float-over-input":true,"clearable":_vm.clearable,"required":_vm.required},on:{"focus":function($event){_vm.focus=true},"itemSelected":_vm.itemSelected,"cleared":function($event){return _vm.$emit('cleared')}},scopedSlots:_vm._u([{key:"beforeSuggestions",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var search = ref.search;
return [(search && search.length >= 2)?_c('div',{staticClass:"create",on:{"click":function($event){return _vm.selectBare(search)}}},[_c('button',{staticClass:"btn btn-outline-primary"},[_vm._v(" "+_vm._s(_vm.$it('global.use', 'Use'))+" "+_vm._s(_vm.labelText)+": "),_c('span',{staticClass:"font-mono"},[_vm._v(_vm._s(search))])])]):_vm._e()]}},{key:"suggestion",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c('div',{staticClass:"item",class:{selected: selected}},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"thumb"},[_c('img',{attrs:{"src":item.asset.thumb,"width":"40"}})])]),_c('div',{staticClass:"col"},[_c('label',[_vm._v(_vm._s(item.asset.name))]),_c('span',{staticClass:"font-mono"},[_vm._v(_vm._s(item.asset.serialNumber))])])])])]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})}
var staticRenderFns = []

export { render, staticRenderFns }