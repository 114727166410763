














import { Component, Prop, Vue } from 'vue-property-decorator';
import Card from '@/layouts/back-office/elements/Card.vue';

@Component({ components: { Card } })
export default class InspectionCreateLocationCard extends Vue {
  @Prop(String) private title!: string;
  @Prop(Array) private tabs!: string[];
  @Prop({type: Boolean, default: false}) private error!: boolean;

  private current = 0;

  public open(index: number) {
    if (this.current === index) {
      return;
    }
    this.current = index;
    this.$emit('switch', this.current);
  }
}
