

































































import { Component, Vue } from 'vue-property-decorator';
import {
  CreateInspectionData,
  CreateInspectionData_paginateFlows_data,
  CreateInspectionData_paginateFlows_data_group,
  CreateInspectionData_paginateFlows_data_inspectionType,
  CreateInspectionVariables,
  InspectionCreateAutoFill_paginateInspections_data,
} from '@/types/intrador';
import { Address } from '@/layouts/back-office/elements/input/InputAddress.vue';
import BackPress from '@/layouts/back-office/main/BackPress.vue';
import Card from '@/layouts/back-office/elements/Card.vue';
import InspectionCreateCardAsset, { Asset } from '@/components/inspection/create/InspectionCreateCardAsset.vue';
import InspectionCreateCardContact from '@/components/inspection/create/InspectionCreateCardContact.vue';
import InspectionCreateCardInspector from '@/components/inspection/create/InspectionCreateCardInspector.vue';
import InspectionCreateCardInspectionType from '@/components/inspection/create/InspectionCreateCardInspectionType.vue';
import InspectionCreateCardLocation from '@/components/inspection/create/InspectionCreateCardLocation.vue';
import InspectionCreateCardPlanning from '@/components/inspection/create/InspectionCreateCardPlanning.vue';
import InspectionCreateCardPreview from '@/components/inspection/create/InspectionCreateCardPreview.vue';
import InspectionCreateCardRemark from '@/components/inspection/create/InspectionCreateCardRemark.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import {RawLocation, Route} from 'vue-router/types/router';
import {Location} from 'vue-router';

export interface DateRange {
  start: Date;
  end: Date;
}

export interface InspectionCreateErrors {
  inspectionType: string | null;
  planning: string | null;
  serialNumber: string | null;
  licensePlate: string | null;
  group: string | null;
  make: string | null;
  type: string | null;
  yearOfManufacture: string | null;
  externalId: string | null;
  inspector: string | null;
  address: any | null;
  contact: any | null;
  dealer: string | null;
  query: string | null;
}

export interface Groups {
  [key: string]: CreateInspectionData_paginateFlows_data_group;
}

export interface Type extends CreateInspectionData_paginateFlows_data_inspectionType {
  groups: Groups;
}

export interface Types {
  [key: string]: Type;
}

export interface User {
  id: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  mobile: string | null;
}

interface EnhancedCreateInspectionData extends CreateInspectionData {
  types: Types;
}

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: {
    BackPress,
    Card,
    InspectionCreateCardAsset,
    InspectionCreateCardContact,
    InspectionCreateCardInspectionType,
    InspectionCreateCardInspector,
    InspectionCreateCardLocation,
    InspectionCreateCardPlanning,
    InspectionCreateCardPreview,
    InspectionCreateCardRemark,
    Spinner,
  },
})
export default class CreateInspection extends Vue {

  private static readonly OVERVIEW: string = 'inspections';
  private static readonly CREATED: string = 'created';

  private types: Types = {};
  private loading = false;

  // TODO This should come from inspection type
  private get planningIsRange() {
    return this.inspectionType === '3' || this.inspectionType === '558';
  }

  private inspectionType: string | null = null;
  private planning: DateRange | Date | null = null;

  private asset: Asset = {
    serialNumber: null,
    group: {
      id: '',
    },
    make: null,
    type: null,
    yearOfManufacture: null,
    externalId: null,
    licensePlate: null,
  };

  private address: Address = {
    id: null,
    placeId: null,
    name: null,
    addressLine1: null,
    city: null,
    zipCode: null,
    state: null,
    country: null,
    remark: null,
    longitude: null,
    latitude: null,
    externalId: null,
  };

  private commonContacts: User[] = [];
  private contact: User = {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
    mobile: null,
  };

  private inspector: User | null = null;
  private trusted: boolean = false;
  private remark: string | null = null;
  private isCreated: boolean = false;

  private errors: InspectionCreateErrors = {
    inspectionType: null,
    planning: null,
    serialNumber: null,
    licensePlate: null,
    group: null,
    make: null,
    type: null,
    yearOfManufacture: null,
    externalId: null,
    inspector: null,
    address: null,
    contact: null,
    dealer: null,
    query: null,
  };

  protected beforeRouteLeave(
    to: Route, from: Route, next: (to?: RawLocation | false | ((vm: any) => any) | void) => void) {
    if (this.isCreated) {
      return next();
    }
    if (!this.inspectionType) {
      return next();
    }
    const confirmLeaving = window.confirm(
      `${this.$it('you-have-unsaved-changes', 'You have unsaved changes')}. ${this.$it(
        'inspection.confirm-closure', 'Do you really want to leave this inspection?',
      )}`,
    );
    if (!confirmLeaving) {
      return next(false);
    }
    next();
  }

  private get displayErrors() {
    let errors: { [key: string]: any } = this.errors;

    // Flatten address errors
    if (errors.address && typeof errors.address === 'object') {
      errors = {
        ...errors,
        ...errors.address,
      };

      delete errors.address;
    }


    return errors;
  }

  /**
   * When available gets the planned at date
   */
  private get plannedAt() {
    if (this.planning === null) {
      return null;
    }

    if (this.planning instanceof Date) {
      return this.$toDate12Hour(this.planning);
    }

    return this.$toDate12Hour(this.planning.start);
  }

  /**
   * When available gets the due at date
   */
  private get dueAt() {
    if (this.planning === null || this.planning instanceof Date) {
      return null;
    }

    return this.$toDate12Hour(this.planning.end);
  }

  /**
   * The GraphQL mutation variables
   */
  private get variables(): CreateInspectionVariables | null {
    if (!this.currentInspectionType || !this.inspector) {
      return null;
    }

    let contact = null;
    if (this.contact.id || this.contact.firstName) {
      contact = {
        id: this.contact.id,
        email: this.contact.email,
        firstName: this.contact.firstName,
        lastName: this.contact.lastName,
        phone: this.contact.phone,
        mobile: this.contact.mobile,
      };
    }

    let group = null;
    if (this.asset.group && this.asset.group.id) {
      group = this.asset.group;
    }

    return {
      inspection: {
        inspectionType: {
          id: this.currentInspectionType.id,
        },
        plannedAt: this.plannedAt ? this.plannedAt.toISOString() : null,
        dueAt: this.dueAt ? this.dueAt.toISOString() : null,
        asset: {
          id: this.asset.id,
          group,
          make: this.asset.make,
          type: this.asset.type,
          yearOfManufacture: this.asset.yearOfManufacture,
          serialNumber: this.asset.serialNumber,
          licensePlate: this.asset.licensePlate,
        },
        inspector: this.inspector.id ? { id: this.inspector.id } : { email: this.inspector.email },
        trusted: this.trusted,
        externalId: this.asset.externalId,
        remark: this.remark,
        address: {
          id: this.address.id as string,
          name: this.address.name,
          addressLine1: this.address.addressLine1,
          zipCode: this.address.zipCode,
          city: this.address.city,
          state: this.address.state,
          country: this.address.country,
          latitude: this.address.latitude,
          longitude: this.address.longitude,
          remark: this.address.remark,
          dealer: this.address.dealer,
        },
        contact,
      },
    };
  }

  /**
   * Get the current inspection type definition or null if not selected
   */
  private get currentInspectionType() {
    return this.inspectionType ? this.types[this.inspectionType] || null : null;
  }

  /**
   * Get the current group definition or null if not selected
   */
  private get currentGroup() {
    return this.asset.group.id && this.asset.group.id !== ''
      ? this.currentInspectionType?.groups[this.asset.group.id] || null
      : null;
  }

  /**
   * Returns whether the input in the forms is valid or not
   *
   * @private
   */
  private get valid() {
    return !Object.values(this.errors).some((e: any) => e !== null);
  }

  /**
   * Method called when the asset changed (due to serial number select)
   */
  private assetUpdated(data: InspectionCreateAutoFill_paginateInspections_data) {
    if (data.address) {
      this.address.id = data.address.id;
      this.address.placeId = undefined;
      this.address.name = data.address.name;
      this.address.latitude = data.address.latitude;
      this.address.longitude = data.address.longitude;
    }

    if (!this.currentInspectionType?.dealerRequired && data.contact) {
      this.contact.id = data.contact.id;
      this.contact.email = data.contact.email;
      this.contact.firstName = data.contact.firstName;
      this.contact.lastName = data.contact.lastName;
    }
  }
  /**
   * Method to define location for back button
   */
  private back(): Location {
    const previous = this.$route.meta.previous;
    if (!previous) {
      return { name: CreateInspection.OVERVIEW };
    }
    if (previous.name === CreateInspection.OVERVIEW && previous.query.hasOwnProperty(CreateInspection.CREATED)) {
      delete previous.query[CreateInspection.CREATED];
      return {name: previous.name, query: previous.query};
    }
    return previous;
  }

  /**
   * Method called when the common contacts of the location are changed
   */
  private commonContactsUpdated(commonContacts: User[]) {
    this.commonContacts = commonContacts;
  }

  /**
   * Method called when trusted ticket is changed
   */
  private trustedUpdated(trusted: boolean) {
    this.trusted = trusted;
  }

  /**
   * Validate the data and present errors when necessary
   */
  private validate() {
    this.errors.inspectionType = this.inspectionType === null
      // tslint:disable-next-line:max-line-length
      ? this.$it('inspection.create.error.inspection-type.required', 'The inspection type is required') as string
      : null;

    if (this.planningIsRange && (this.plannedAt === null || this.dueAt === null)) {
      this.errors.planning = this.$it('inspection.create.error.planning.range-required', 'The inspection should have a start and end date') as string;
    } else if (this.plannedAt === null) {
      this.errors.planning = this.$it('inspection.create.error.planning.required', 'The inspection should at least have a start date') as string;
    } else {
      this.errors.planning = null;
    }

    if (!this.asset.serialNumber) {
      this.errors.serialNumber = this.$it('inspection.create.error.serial-number.required', 'The asset serial number is required') as string;
    } else if (this.asset.serialNumber.length < 2) {
      this.errors.serialNumber = this.$it('inspection.create.error.serial-number.too-short', 'The asset serial number should have at least two characters') as string;
    } else {
      this.errors.serialNumber = null;
    }

    if (this.asset.licensePlate && this.asset.licensePlate.length < 2) {
      this.errors.licensePlate = this.$it('inspection.create.error.license-plate.required', 'The asset license plate should have at least two characters when filled') as string;
    } else {
      this.errors.licensePlate = null;
    }

    this.errors.group = this.currentInspectionType?.groupRequired && !this.asset.group.id
      ? this.$it('inspection.create.error.group.required', 'The asset type is required') as string
      : null;

    if (!this.asset.make) {
      this.errors.make = this.$it('inspection.create.error.make.required', 'The asset make is required') as string;
    } else if (this.asset.make.length < 2) {
      this.errors.make = this.$it('inspection.create.error.make.too-short', 'The asset make should have at least two characters') as string;
    } else {
      this.errors.make = null;
    }

    if (!this.asset.type) {
      this.errors.type = this.$it('inspection.create.error.model.required', 'The asset model is required') as string;
    } else if (this.asset.type.length < 2) {
      this.errors.type = this.$it('inspection.create.error.type.too-short', 'The asset type should have at least two characters') as string;
    } else {
      this.errors.type = null;
    }

    this.errors.inspector = this.inspector === null
      ? this.$it('inspection.create.error.inspector.required', 'The inspector is required') as string
      : null;

    this.errors.address = this.$refs.cLocation &&
      (this.$refs.cLocation as InspectionCreateCardLocation).check() || null;
    this.errors.contact = this.$refs.cContact &&
      (this.$refs.cContact as InspectionCreateCardContact).check() || null;

    // Also validate location
    return this.valid;
  }

  /**
   * Create the actual inspection
   */
  private async save(toggleLoading: ((state: boolean | null) => boolean)) {
    // Do not execute method twice
    if (this.loading) {
      return;
    }

    // Reset previous query
    this.errors.query = null;

    // If not validated, we not submittin'
    if (!this.validate()) {
      return;
    }

    this.loading = toggleLoading(true);

    // Try to submit
    try {
      await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/CreateInspection.gql'),
        variables: this.variables,
      });
      this.isCreated = true;
      // Results are there, redirect to list page
      this.$router.push({
        name: CreateInspection.OVERVIEW,
        query: { [CreateInspection.CREATED]: 'success' },
      });
    } catch (error) {
      this.errors.query = error.message;
      this.loading = toggleLoading(false);
    }
  }

  /**
   * Update the data gathered from the query
   *
   * @param data
   * @private
   */
  private update(data: CreateInspectionData): EnhancedCreateInspectionData {
    const enhanced: EnhancedCreateInspectionData = { ...data, types: {} };

    if (data.paginateFlows && data.paginateFlows.data) {
      this.types = data.paginateFlows.data.reduce((d: Types, o: CreateInspectionData_paginateFlows_data | null) => {
        if (!o || !o.inspectionType || !o.group || !o.isEntityFlow) {
          return d;
        }

        if (!(o.inspectionType.id in d)) {
          d[o.inspectionType.id] = {
            ...o.inspectionType,
            groups: {},
          };
        }

        d[o.inspectionType.id].groups[o.group.id] = o.group;

        return d;
      }, {});

      enhanced.types = this.types;
    }

    if (data.paginateInspectionTypes && data.paginateInspectionTypes.data) {
      data.paginateInspectionTypes.data.forEach((inspectionType) => {
        if (!inspectionType) {
          return;
        }

        if (!(inspectionType.id in this.types)) {
          this.types[inspectionType.id] = {
            ...inspectionType,
            groupRequired: false,
            groups: {},
          };
        }
      });
    }

    return enhanced;
  }
}
