









































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
  CheckExternalId,
  GroupInput,
  InspectionAssetInput,
  InspectionCreateAutoFill,
  InspectionCreateAutoFill_paginateInspections_data,
  InspectionCreateAutoFill_paginateInspections_data_asset,
  InspectionCreateAutoFillVariables,
} from '@/types/intrador';
import * as Sentry from '@sentry/browser';
import { Groups } from '@/pages/inspection/create/Create.vue';
import InspectionCreateCardMixin from '@/components/inspection/create/InspectionCreateCardMixin';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import InputMake from '@/layouts/back-office/elements/input/InputMake.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputType from '@/layouts/back-office/elements/input/InputType.vue';
import InputSerialNumber from '@/components/elements/input/InputSerialNumber.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

export interface Asset extends InspectionAssetInput {
  externalId: string | null;
  group: GroupInput;
}

@Component({
  components: {
    InputMake,
    InputSelect,
    InputSerialNumber,
    InputText,
    InputType,
    Spinner,
  },
})
export default class InspectionCreateCardAsset extends Mixins(InspectionCreateCardMixin) {

  @Prop(Object) protected groups!: Groups;
  @Prop(Boolean) protected groupRequired!: boolean;

  protected makeTypeRequired: boolean = true;

  protected matchingSerialNumberAsset: InspectionCreateAutoFill_paginateInspections_data_asset | null = null;
  protected serialNumberCheckTimer: number | null = null;

  protected externalIdExists = false;
  protected externalIdVerifying = false;
  protected externalIdTimer: number | null = null;

  protected initialValues: Asset = {
    serialNumber: null,
    licensePlate: null,
    group: {
      id: '',
    },
    make: null,
    type: null,
    yearOfManufacture: null,
    externalId: null,
  };

  protected value: Asset = {...this.initialValues};

  /**
   * Whether this inspectionType has any groups attached to it
   */
  private get groupsPresent() {
    return Object.keys(this.groups).length > 0;
  }

  /**
   * Method called when serial number selected when searching
   * for one this method should auto fill all possible fields
   *
   * @param data
   * @private
   */
  private serialChanged(data: InspectionCreateAutoFill_paginateInspections_data | string) {
    if (typeof data === 'string') {
      this.checkSerialNumber(data);
      return;
    }

    this.resetSerialNumberCheck();

    if (!data) {
      return;
    }
    if (data.asset) {
      this.value.id = data.asset.id;

      this.value.serialNumber = data.asset.serialNumber || null;
      this.value.yearOfManufacture = data.asset.yearOfManufacture || null;

      if (data.asset.makeModel) {
        this.value.make = data.asset.makeModel.make!.name || null;
        this.value.type = data.asset.makeModel.name || null;
      }

      if (data.asset.group && data.asset.group.id in this.groups) {
        this.value.group.id = data.asset.group.id;
      } else {
        this.value.group.id = '';
      }

      this.$emit('changed', data);
    }
  }

  private resetAsset() {
    this.value = {...this.initialValues};
  }

  /**
   * Reset the serial number matching
   *
   * @private
   */
  private resetSerialNumberCheck() {
    this.matchingSerialNumberAsset = null;
    if (this.serialNumberCheckTimer) {
      clearTimeout(this.serialNumberCheckTimer);
      this.serialNumberCheckTimer = null;
    }
  }

  /**
   * Method that checks the serial number if there is not already one
   *
   * @param serialNumber The externalId to check
   *
   * @private
   */
  private checkSerialNumber(serialNumber: string) {
    // Clear timer if it exists
    if (this.serialNumberCheckTimer) {
      clearTimeout(this.serialNumberCheckTimer);
    }

    // If the value is bogus, stop here
    if (serialNumber.length < 1) {
      this.resetSerialNumberCheck();
      return;
    }

    this.serialNumberCheckTimer = window.setTimeout(async () => {
      try {
        const {data} = await this.$apollo.query<InspectionCreateAutoFill, InspectionCreateAutoFillVariables>({
          query: require('@/graphql/InspectionCreateAutoFill.gql'),
          variables: {
            search: serialNumber,
          },
        });

        this.matchingSerialNumberAsset = null;
        if (data.paginateInspections &&  data.paginateInspections.data && data.paginateInspections.data?.length > 0) {
          const inspection = data.paginateInspections.data[0];
          this.matchingSerialNumberAsset = inspection?.asset ?? null;
        }
      } catch (e) {
        Sentry.captureException(e);
      }

      this.serialNumberCheckTimer = null;
    });
  }

  /**
   * Method that verifies if the externalId entered is already known in our system
   *
   * @param input The externalId to check
   *
   * @private
   */
  private checkExternalId(input: string | null) {
    // Clear timer if it exists
    if (this.externalIdTimer) {
      clearTimeout(this.externalIdTimer);
    }

    // If the value is bogus, stop here
    if (input === null || input.length < 1) {
      this.externalIdExists = false;
      this.externalIdVerifying = false;
      return;
    }

    this.externalIdVerifying = true;

    // Check on the server whether this externalId exists
    this.externalIdTimer = window.setTimeout(async () => {
      try {
        const {data} = await this.$apollo.query<CheckExternalId>({
          query: require('@/graphql/queries/inspection/CheckExternalId.gql'),
          variables: {
            externalId: input,
          },
        });

        // Do we have a response?
        if (data && data.paginateInspections) {
          this.externalIdExists = data.paginateInspections.total > 0;
        }
      } catch (e) {
        Sentry.captureException(e);
      }

      this.externalIdVerifying = false;
    }, 300);
  }
}
