



















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import QueryAutocomplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import ComboSelect from '@/layouts/back-office/elements/input/InputComboSelect.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';
import { InspectionCreateAutoFill_paginateInspections_data } from '@/types/intrador';

@Component({
  components: {ComboSelect, QueryAutocomplete},
})
export default class InputSerialNumber extends Mixins(InputMixin) {
  @Prop(String) private text!: string;
  @Prop(String) private placeholder?: string;
  @Prop({type: Boolean, default: false}) private onlyUseOptions!: boolean;
  @Prop({type: Array, default: () => []}) private options!: any[];
  @Prop({type: Boolean, default: false}) private fixed!: boolean;

  private get labelText() {
    return this.$it('global.serial-number', 'Serial Number');
  }

  private valueKey(inspection: InspectionCreateAutoFill_paginateInspections_data) {
    return inspection.asset!.serialNumber;
  }

  private itemSelected(item: any) {
    this.$emit('itemSelected', item);
  }

  private selectBare(search: string) {
    (this.$refs.combo as any).enterPressed(search);
  }
}
