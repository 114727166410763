




















































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {
  AddressType,
  PaginateDealerBranches_paginateDealerBranches_data,
  PaginateDealersVariables,
  SearchAddresses_searchAddresses,
  SearchAddresses_searchAddresses_Address,
  SearchAddressesVariables,
} from '@/types/intrador';
import InspectionCreateCardMixin from '@/components/inspection/create/InspectionCreateCardMixin';
import InspectionCreateCardTabs from '@/components/inspection/create/InspectionCreateCardTabs.vue';
import InputAddress, {Address, Dealer} from '@/layouts/back-office/elements/input/InputAddress.vue';
import InputDealer from '@/components/elements/input/Input.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {
    InspectionCreateCardTabs,
    InputAddress,
    InputDealer,
    InputText,
    Spinner,
  },
})
export default class InspectionCreateLocationCard extends Mixins(InspectionCreateCardMixin) {

  private static readonly TYPE_ADDRESS = 'Address';

  @Prop(Boolean) protected dealerRequired!: boolean;

  protected currentTab: number = 0;
  protected dealerId: string | null = null;
  protected search: string | null = null;

  protected dealer: Dealer | null = null;

  protected value: Address = {
    id: null,
    placeId: null,
    name: null,
    addressLine1: null,
    city: null,
    zipCode: null,
    state: null,
    country: null,
    remark: null,
    longitude: null,
    latitude: null,
    externalId: null,

    dealer: undefined,
  };

  private get query() {
    return this.dealerRequired
      ? require('@/graphql/queries/branches/PaginateBranches.gql')
      : require('@/graphql/Addresses.gql');
  }

  private get variables(): SearchAddressesVariables | PaginateDealersVariables {
    return {
      search: this.search,
      searchableFields: ['name'],
      type: this.dealerRequired ? AddressType.branch : AddressType.addressBook,
    };
  }

  /**
   * Check whether the input given is valid, returns null when all is well
   * otherwise an object containing error per field will be returned for display
   */
  public check() {
    if (this.currentTab === 1) {
      let errors: any = {};

      if (this.dealerRequired && this.dealerId === null) {
        errors.dealer = this.$it('inspection.create.error.dealer.required', 'The location dealer is required');
      }

      // Check and validate input
      const inpAddr = (this.$refs.inputAddress as InputAddress);
      if (!inpAddr!.check()) {
        errors = {
          ...errors,
          ...inpAddr.inputErrors,
        };
      }

      if (Object.keys(errors).length > 0) {
        return errors;
      }

      return null;
    }

    // When selecting "existing" (from address book or Google), check if data is present
    return !this.value.id && !this.value.placeId
      ? this.$it('inspection.create.error.address.required', 'The location is required')
      : null;
  }

  private hasExistingAddresses(data: SearchAddresses_searchAddresses[]): boolean {
    return this.filterAddresses(data).length > 0;
  }

  /**
   * This will keep existing addresses and filter out GooglePlaces
   */
  private filterAddresses(data: SearchAddresses_searchAddresses[]): SearchAddresses_searchAddresses[] {
    return Object.values(data)
      .filter((element: SearchAddresses_searchAddresses): boolean => {
        return element.__typename === InspectionCreateLocationCard.TYPE_ADDRESS;
    });
  }

  /**
   * Open a different tab
   */
  private switchTab(index: number) {
    this.currentTab = index;

    this.dealerId = null;
    this.dealer = null;

    // Clear value
    this.value = {
      id: null,
      placeId: null,
      name: null,
      addressLine1: null,
      city: null,
      zipCode: null,
      state: null,
      country: null,
      remark: null,
      longitude: null,
      latitude: null,
      externalId: null,
      dealer: undefined,
    };
  }

  /**
   * When the user selects a branch
   */
  private selectBranch(address: PaginateDealerBranches_paginateDealerBranches_data) {
    this.value.id = address.id;
    this.value.name = address.name;
    this.value.latitude = address.latitude;
    this.value.longitude = address.longitude;
  }

  /**
   * When the user selects an address
   */
  private selectAddress(address: SearchAddresses_searchAddresses) {
    if (address.__typename === 'GooglePlaces') {
      this.value.id = undefined;
      this.value.placeId = address.id;

      // TODO: Coordinates should be added here as well
    } else if (address.__typename === 'Address') {
      this.value.id = address.id;
      this.value.placeId = undefined;
      this.value.latitude = (address as SearchAddresses_searchAddresses_Address).latitude;
      this.value.longitude = (address as SearchAddresses_searchAddresses_Address).longitude;

      this.$emit('contacts', (address as SearchAddresses_searchAddresses_Address).contacts);
    }

    this.value.name = address.name;
  }

  /**
   * Update the dealer information
   *
   * @param dealer
   * @private
   */
  private dealerSelected(dealer: PaginateDealerBranches_paginateDealerBranches_data | string | null) {
    if (dealer) {
      this.dealer = typeof dealer === 'string'
        ? { input: { name: (dealer as string), externalId: this.value.externalId || '' } }
        : { id: (dealer as PaginateDealerBranches_paginateDealerBranches_data).id };
    } else {
      this.dealer = null;
    }
  }
}
