



















import { Component, Mixins, Prop } from 'vue-property-decorator';
import { PaginateDealerAccounts_paginateDealerAccounts_data } from '@/types/intrador';
import InspectionCreateCardMixin from '@/components/inspection/create/InspectionCreateCardMixin';
import InputInspector from '@/components/elements/input/InputInspector.vue';

@Component({ components: { InputInspector } })
export default class InspectionCreateCardInspector extends Mixins(InspectionCreateCardMixin) {
  @Prop(Boolean) private trusted!: boolean;

  private inspector: PaginateDealerAccounts_paginateDealerAccounts_data | string | null = null;
  private isTrusted: boolean | null = null;

  private mounted() {
    this.inspector = this.value;
    this.isTrusted = this.trusted;
  }

  /**
   * Method called when inspector selected when searching for one this
   *
   * @param inspector
   * @private
   */
  private inspectorChanged(inspector: PaginateDealerAccounts_paginateDealerAccounts_data | string) {
    if (typeof inspector === 'object') {
      this.value = {
        id: inspector.id,
        email: inspector.email || '',
        name: inspector.name || undefined,
        firstName: inspector.firstName || undefined,
        lastName: inspector.lastName || undefined,
      };

      return;
    }

    this.value = {
      email: inspector,
    };
  }
}
