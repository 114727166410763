



























































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { PaginateContacts_paginateContacts_data, PaginateContactsVariables } from '@/types/intrador';
import { User } from '@/pages/inspection/create/Create.vue';
import InspectionCreateCardMixin from '@/components/inspection/create/InspectionCreateCardMixin';
import InspectionCreateCardTabs from '@/components/inspection/create/InspectionCreateCardTabs.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {
    InspectionCreateCardTabs,
    InputText,
    Spinner,
  },
})
export default class InspectionCreateCardContact extends Mixins(InspectionCreateCardMixin) {
  @Prop({ type: Array, default: () => ([])}) protected commonContacts!: User[];

  protected currentTab: number = 0;
  protected search: string | null = null;

  protected value: User = {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    mobile: null,
  };

  private get variables(): PaginateContactsVariables {
    return {
      search: this.search,
      searchableFields: ['name'],
    };
  }

  /**
   * Validate the input given
   */
  public check() {
    if (this.currentTab === 1) {
      const errors: any = {};

      // When one of the fields is provided, require a first and last name.
      if (this.value.firstName || this.value.lastName || this.value.email || this.value.phone || this.value.mobile) {
        if (!this.value.firstName || this.value.firstName.length < 2) {
          errors.firstName = this.$it('inspection.create.error.contact.first-name.required', 'The contact first name is required when specifying a contact');
        }

        if (!this.value.lastName || this.value.lastName.length < 2) {
          errors.lastName = this.$it('inspection.create.error.contact.last-name.required', 'The contact last name is required when specifying a contact');
        }
      }

      if (Object.keys(errors).length > 0) {
        return errors;
      }
    }

    return null;
  }

  /**
   * Switch to different tab
   *
   * @param index
   * @private
   */
  private switchTab(index: number) {
    this.currentTab = index;

    this.value = {
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      mobile: null,
    };
  }

  /**
   * Select an existing location
   *
   * @param contact
   * @private
   */
  private select(contact: PaginateContacts_paginateContacts_data | User) {
    this.value.id = contact.id;
    this.value.email = contact.email;
    this.value.firstName = contact.firstName;
    this.value.lastName = contact.lastName;
  }

  @Watch('commonContacts')
  private commonContactsChanged() {
    this.search = null;
  }
}
