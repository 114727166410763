import { Component, Emit, Model, Prop, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore
import { InspectionCreateErrors } from '@/pages/inspection/create/Create.vue';
import Card from '@/layouts/back-office/elements/Card.vue';

@Component({ components: { Card } })
export default class InspectionCreateCardMixin extends Vue {
  @Prop(Object) protected errors!: InspectionCreateErrors;
  @Prop(Number) protected tabIndex?: number;

  protected value: any = null;

  @Model('change') protected model!: any;
  @Watch('model', { immediate: true })
  protected onModelChange(value: any) {
    this.value = value;
  }

  @Watch('value')
  @Emit('change')
  protected onValueChange(value: any) {
    return value;
  }
}
