



































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
  CreateInspectionData_paginateFlows_data_group,
  CreateInspectionData_paginateFlows_data_inspectionType,
} from '@/types/intrador';
import { User } from '@/pages/inspection/create/Create.vue';
import { Asset } from '@/components/inspection/create/InspectionCreateCardAsset.vue';
import { Address } from '@/layouts/back-office/elements/input/InputAddress.vue';
import InspectionCreateCardMixin from '@/components/inspection/create/InspectionCreateCardMixin';
import InspectionInspectorAvatar from '@/components/inspection/InspectionInspectorAvatar.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';

@Component({ components: { InspectionInspectorAvatar, SpinnerButton }})
export default class InspectionCreateCardPreview extends Mixins(InspectionCreateCardMixin) {

  @Prop(Object) private inspectionType?: CreateInspectionData_paginateFlows_data_inspectionType;
  @Prop(Date) private plannedAt?: Date;
  @Prop(Date) private dueAt?: Date;
  @Prop(Object) private group?: CreateInspectionData_paginateFlows_data_group;
  @Prop(Object) private asset!: Asset;
  @Prop(Object) private inspector?: User;
  @Prop(Boolean) private trusted!: boolean;
  @Prop(Object) private address!: Address;
  @Prop(Object) private contact?: User;

  /**
   * Creates a name for this inspection based on Make, Type and Year
   */
  private get name() {
    return [this.asset.make, this.asset.type, this.asset.yearOfManufacture].filter((p) => !!p).join(' ');
  }

  private get addressURL() {
    if (this.address.latitude && this.address.longitude) {
      return `https://maps.googleapis.com/maps/api/staticmap?center=${this.address.latitude},${this.address.longitude}`
        + `&size=270x150&scale=2&zoom=7&markers=red|${this.address.latitude},${this.address.longitude}`
        + `&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`;
    }

    return `https://maps.googleapis.com/maps/api/staticmap?center=0,0&size=270x150`
      + `&scale=2&zoom=0&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`;
  }

}
