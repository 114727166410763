











import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Types } from '@/pages/inspection/create/Create.vue';
import InspectionCreateCardMixin from '@/components/inspection/create/InspectionCreateCardMixin';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';

@Component({ components: { InputSelect } })
export default class InspectionCreateCardInspectionType extends Mixins(InspectionCreateCardMixin) {

  @Prop(Object) private types!: Types;

  protected mounted() {
    const keys = Object.keys(this.types);
    if (keys.length === 1) {
      this.value = keys[0];
    }
  }
}
