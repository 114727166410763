








import { Component, Mixins } from 'vue-property-decorator';
import InspectionCreateCardMixin from '@/components/inspection/create/InspectionCreateCardMixin';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';

@Component({ components: { InputTextarea } })
export default class InspectionCreateCardRemark extends Mixins(InspectionCreateCardMixin) {

}
