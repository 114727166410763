


































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import InspectionCreateCardMixin from '@/components/inspection/create/InspectionCreateCardMixin';

@Component
export default class InspectionCreateCardPlanning extends Mixins(InspectionCreateCardMixin) {

  @Prop(Boolean) private planningIsRange!: boolean;

  private now = new Date();
  /* TODO: This should originate from the inspectionType */
  private planningHasDueDate = false;

  /**
   * Whether this type uses a range or single planning date
   *
   * @private
   */
  private get planningMode() {
    return this.planningIsRange || this.planningHasDueDate ? 'range' : 'single';
  }

  /**
   * This method will set the previous start date as single date
   */
  private setSingleDate() {
    if (this.value === null || this.value instanceof Date) {
      return;
    }
    this.value = this.value.start;
  }

  /**
   * This method will reset dates
   */
  private resetDate(): void {
    this.value = null;
  }

  @Watch('planningMode')
  private planningModeUpdated() {
    if (this.planningMode === 'single') {
        return this.setSingleDate();
    }
    this.resetDate();
  }
}
